.cookie-privacy-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #333;
  color: #fff;
  padding: 15px;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  width: 100%;  /* Ensure full width */
  height: auto; /* Ensure height adjusts to content */
}

.cookie-privacy-banner p {
  margin: 0;
  padding: 0 10px;
  font-size: 1rem;
}

.cookie-privacy-banner button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .cookie-privacy-banner {
    padding: 10px;
    flex-direction: column;
    text-align: center;
    display: flex; /* Ensure flexbox works */
    width: 100%;  /* Ensure it takes the full width on mobile */
    bottom: 0; /* Explicitly set the bottom position */
    z-index: 1000; /* Ensure it's above other content */
  }

  .cookie-privacy-banner button {
    width: 90%;
    margin: 10px auto;
  }
}

/* Add this to ensure body is 100% height and no overflow that affects fixed elements */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}
